import { Column } from 'exceljs';
import { useState, useEffect } from 'react';
import { ILearningGamePlayerRank } from '../../interface/learning-game-player-rank.interface';
import { IDatableColumn } from '../../common/leaderboard/column.interface';
import Leaderboard from '../../common/leaderboard';
import { months } from '../../common/constants';
import {
  fetchJourney,
  getGenieLearningGamePlayerLeaderboard,
} from '../../common/api/apicall';
import { useParams } from 'react-router-dom';

const includeNonPostive = true;

export default function Participants() {
  const [data, setData] = useState<ILearningGamePlayerRank[]>([]);
  const { id: journeyId } = useParams();
  const [journeyDetails, setJourneyDetails] = useState<{
    name: string;
    divisionId: string;
  }>();

  const columns: IDatableColumn[] = [
    {
      name: 'Learner Id',
      key: 'learnerId',
    },
    {
      name: 'Name',
      key: 'name',
    },
    {
      name: 'Email',
      key: 'email',
    },
    {
      name: 'Highest Score',
      key: 'highestScore',
      type: 'number',
    },
    {
      name: 'Date',
      key: 'completedAt',
      type: 'string',
      formatter: '',
    },
  ];

  // const newColumns =
  //   (journeyDetails as any)?.questions?.map((item: any, index: any) => {
  //     return {
  //       header: `Q${index + 1}`,
  //       key: `q-${item._id}`,
  //       width: 50,
  //     };
  //   }) || [];

  const newColumns =
    ((journeyDetails as any)?.gameFormat === 'JumblesGame'
      ? (journeyDetails as any)?.jumbles
      : (journeyDetails as any)?.questions
    )?.map((item: any, index: any) => {
      return {
        header: `Q${index + 1}`,
        key: `q-${item._id}`,
        width: 50,
      };
    }) || [];

  const worksheetColumns = [
    { header: 'Learner Id', key: 'learnerId', width: 50 } as Column,
    { header: 'Name', key: 'name', width: 50 } as Column,
    { header: 'Email', key: 'email', width: 50 } as Column,
    {
      header: 'Highest Score',
      key: 'highestScore',
      width: 15,
      alignment: { horizontal: 'center' },
    } as Column,
    { header: 'Date', key: 'completedAt', width: 15 } as Column,
    ...newColumns,
  ];

  useEffect(() => {
    async function getGenieJourney() {
      try {
        const journey = await fetchJourney(journeyId as string, false);
        setJourneyDetails(journey);
        getGenieLearningGamePlayerLeaderboard(journey.divisionId, false, {
          includeNonPostive,
        })
          .then((res: any[]) => {
            const filteredPlayers: any[] = [];
            res.forEach((player, index) => {
              player['name'] =
                (player['firstName'] || '') +
                ' ' +
                (player['lastName'] || '') +
                (player['lastname'] || '');

              // 12 Sep, 2022
              if (player.highestScore) {
                player['highestScore'] = player['highestScore'];
              }

              player.analytics.questions.map((item: any) => {
                player[`q-${item.questionId}`] = item.attempt === 0 ? 1 : 0;
              });

              if (player.extraDetail) {
                player['employeeid'] = player.extraDetail['employeeid'];
              }

              if (player['email']?.trim()?.toLowerCase() === 'test@test.com') {
                player['email'] = '';
              }

              if (player['completedAt']) {
                const date = new Date(player['completedAt']);
                player['completedAt'] = `${date.getDate()} ${
                  months[date.getMonth()]
                }, ${date.getFullYear()}`;
              }
              if (player.extraDetail) {
                filteredPlayers.push(player);
              }
            });
            setData(filteredPlayers);
          })
          .catch((error) => console.log('error: ', error));
      } catch (error) {}
    }
    getGenieJourney();
  }, []);

  return (
    <Leaderboard
      data={data}
      columns={columns}
      showIndex={true}
      type="new"
      total={data.length}
      name={journeyDetails?.name}
      // headerCellStyle={classes.tableHeaderCell}
      downloadable={true}
      worksheetColumns={worksheetColumns}
    />
  );
}
