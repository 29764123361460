import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
import GenieStaging from './leaderboards/genie-staging';
import HomeFirstAppraisal2024 from './leaderboards/homefirst-appraisal';
import AsianPaintsDei from './leaderboards/asian-paints/dei';

const AbgSexism = React.lazy(() => import('./leaderboards/abg/sexism'));
const HomeFirstXL2024 = React.lazy(
  () => import('./leaderboards/homefirst-xl-2024'),
);
const Edelweiss = React.lazy(() => import('./leaderboards/edelweiss'));
const Empower = React.lazy(() => import('./leaderboards/empower'));
const HomeFirst2425 = React.lazy(
  () => import('./leaderboards/home-first24-25'),
);
const NestleSwipe = React.lazy(() => import('./leaderboards/nestle-swipe'));
const HomeFirst24 = React.lazy(() => import('./leaderboards/home-first24'));

const BirlaEstatesStaging = React.lazy(
  () => import('./leaderboards/birla-estates-staging'),
);
const Genie = React.lazy(() => import('./leaderboards/genie/index'));

const HorizonV = React.lazy(() => import('./leaderboards/abg/horizon-v'));
const BirlaEstates = React.lazy(() => import('./leaderboards/birla-estates'));
const AsianPaintsCreativeZeal = React.lazy(
  () => import('./leaderboards/asian-paints/creative-zeal'),
);
const AbgPurposeGame = React.lazy(
  () => import('./leaderboards/abg/abg-purpose-game'),
);
const Kbc = React.lazy(() => import('./leaderboards/ceat/kbc'));
const TVS = React.lazy(() => import('./leaderboards/tvs'));
const HomeFirst23 = React.lazy(() => import('./leaderboards/home-first23'));
const AbgInclusive = React.lazy(() => import('./leaderboards/abg'));
const CeatValuesGame = React.lazy(
  () => import('./leaderboards/ceat-values-game'),
);
const BirlaCarbonValuesGame = React.lazy(
  () => import('./leaderboards/birla-carbon-values-game'),
);
const Sony = React.lazy(() => import('./leaderboards/sony'));
const HomeFirst = React.lazy(() => import('./leaderboards/home-first'));
const MarutiValuesGame = React.lazy(
  () => import('./leaderboards/maruti-values-game'),
);
const AsianPaints = React.lazy(() => import('./leaderboards/asian-paints'));
const CocaColaCampusGame = React.lazy(
  () => import('./leaderboards/coca-cola-campus-game'),
);
const CocaColaValuesGame = React.lazy(
  () => import('./leaderboards/coca-cola-values-game'),
);

const CocaColaHccbValuesGame = React.lazy(
  () => import('./leaderboards/coca-cola-values-game-hccb'),
);
const ZetwerkOrientationGame = React.lazy(
  () => import('./leaderboards/zetwerk-orientation-game'),
);
const MurugappaValuesGame = React.lazy(
  () => import('./leaderboards/murugappa'),
);

const Home = React.lazy(() => import('./home'));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sony" element={<Sony />} />
          <Route path="/HomeFirst" element={<HomeFirst />} />
          <Route path="/HomeFirst-2023" element={<HomeFirst23 />} />
          <Route path="/HomeFirst-2024" element={<HomeFirst24 />} />
          <Route path="/HomeFirst-2024-25" element={<HomeFirst2425 />} />
          <Route
            path="/HomeFirst/appraisal-2024"
            element={<HomeFirstAppraisal2024 />}
          />
          <Route path="/HomeFirst/xl-2024" element={<HomeFirstXL2024 />} />
          <Route path="/abg" element={<AbgInclusive />} />
          <Route path="/abg-sexism" element={<AbgSexism />} />
          <Route path="/abg-purpose-game" element={<AbgPurposeGame />} />
          <Route path="/abg-horizonv" element={<HorizonV />} />
          <Route path="/marutivaluesgame" element={<MarutiValuesGame />} />
          <Route path="/asian-paints" element={<AsianPaints />} />
          <Route path="/asian-paints/dei" element={<AsianPaintsDei />} />
          <Route
            path="/asian-paints-creative-zeal"
            element={<AsianPaintsCreativeZeal />}
          />
          <Route
            path="/coca-cola-values-game"
            element={<CocaColaValuesGame />}
          />
          <Route
            path="/coca-cola-campus-game"
            element={<CocaColaCampusGame />}
          />{' '}
          <Route path="/ceat-values-game" element={<CeatValuesGame />} />{' '}
          <Route path="/ceat">
            <Route path="kbc" element={<Kbc />} />
          </Route>
          <Route
            path="/coca-cola-values-game-hccb"
            element={<CocaColaHccbValuesGame />}
          />
          <Route
            path="/birla-carbon-values-game"
            element={<BirlaCarbonValuesGame />}
          />
          <Route
            path="/zetwerk-orientation-game"
            element={<ZetwerkOrientationGame />}
          />
          <Route path="/tvs" element={<TVS />} />
          <Route path="/murugappa" element={<MurugappaValuesGame />} />
          <Route path="/birla-estates" element={<BirlaEstates />} />
          <Route path="/edelweiss-onboarding" element={<Edelweiss />} />
          <Route path="/empower-onboarding" element={<Empower />} />
          <Route
            path="/birla-estates-staging"
            element={<BirlaEstatesStaging />}
          />
          <Route path="/nestle" element={<NestleSwipe />} />
          <Route path="/genie">
            <Route path=":id" element={<Genie />} />
          </Route>
          <Route path="/genie-staging">
            <Route path=":id" element={<GenieStaging />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
